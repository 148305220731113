<template>
  <div style="background: #FFFFFF; padding: 20px">
    <div style="float: right; margin-bottom: 20px">
      <el-button size="small" type="success" @click="showAddDialog">添加</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="tables"
      border
      style="width: 100%">
      <el-table-column
        prop="expandTableName"
        label="表名">
      </el-table-column>
      <el-table-column
        width="100"
        align="center"
        label="字段数量">
        <template #default="{ row }">
          <span @click="goToSetFields(row)" style="color: #3472c7; font-weight: bold; cursor:pointer;">{{ row.fieldNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        align="center"
        label="系统表">
        <template #default="{ row }">
          <span>{{ row.isSystem === 1 ? '是' : '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        label="创建时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="creatorName"
        label="创建人"
        width="160">
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="200">
      </el-table-column>
      <el-table-column
        width="220"
        align="center"
        label="操作">
        <template #default="{ row }">
          <el-button size="mini" type="primary" @click="showEditDialog(row)">编辑</el-button>
          <el-button size="mini" type="success" @click="goToDataList(row)">数据</el-button>
          <el-button disabled size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="addDialogVisible" title="添加表" @close="resetForm" width="400px">
      <el-form v-loading="addLoading" size="small" :model="addForm" :rules="addFormRules" ref="addForm" label-position="right" label-width="50px">
        <el-form-item label="表名" prop="expandTableName">
          <el-input v-model="addForm.expandTableName"></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="alias">
          <el-input v-model="addForm.alias" placeholder="请输入别名，纯英文"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="addForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editDialogVisible" title="编辑表" @close="resetForm" width="400px">
      <el-form v-loading="editLoading" size="small" :model="editForm" :rules="editFormRules" ref="editForm" label-position="right" label-width="50px">
        <el-form-item label="表名" prop="expandTableName">
          <el-input v-model="editForm.expandTableName"></el-input>
        </el-form-item>
        <el-form-item label="别名" prop="alias">
          <el-input disabled v-model="editForm.alias"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="editForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitUpdate">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { expandTablesList, addExpandTable, updateExpandTable } from '@/api/expandTable'

export default {
  name: 'name',
  data () {
    return {
      loading: true,
      addLoading: false,
      editLoading: false,
      tables: [],
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        expandTableName: '',
        alias: '',
        remark: ''
      },
      editForm: {
        expandTableName: '',
        alias: '',
        remark: ''
      },
      addFormRules: {
        expandTableName: [
          { required: true, message: '请输入表名', trigger: 'blur' }
        ],
        alias: [
          { required: true, message: '请输入别名，纯英文', trigger: 'blur' }
        ]
      },
      editFormRules: {
        expandTableName: [
          { required: true, message: '请输入表名', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.expandTablesList()
  },
  methods: {
    async goToSetFields (row) {
      this.$router.push('/settings/project/expandTableField/' + row.alias)
    },
    async goToDataList (row) {
      this.$router.push('/settings/project/expandTableValue/' + row.alias)
    },
    async expandTablesList () {
      this.loading = true
      const res = await expandTablesList({})
      this.loading = false
      this.tables = res.data
    },
    showAddDialog () {
      this.addDialogVisible = true
    },
    showEditDialog (row) {
      this.editDialogVisible = true
      this.editForm = JSON.parse(JSON.stringify(row))
    },
    resetForm () {
      this.$refs.addForm.resetFields()
      this.$refs.editForm.resetFields()
    },
    async submitForm () {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          this.addLoading = true
          const res = await addExpandTable(this.addForm)
          this.addLoading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.addDialogVisible = false
            this.expandTablesList()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async submitUpdate () {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.editLoading = true
          const res = await updateExpandTable(this.editForm)
          this.editLoading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.editDialogVisible = false
            this.expandTablesList()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
